import {VideoArchiveStateConst } from "@soccerwatch/common"
export const stitchingDown = [
  {
    value: 'check',
    label: 'Prüfen'
  },
  {
    value: 'TBD',
    label: 'TBD'
  },
  {
    value: 'topOk',
    label: 'Top Ok'
  },
  {
    value: 'littleOff',
    label: 'Little Off'
  },
  {
    value: 'calibrated',
    label: 'Kalibriert'
  }
]

export const taggingState = [
  {
    value: undefined,
    label: 'NO_TAG'
  },
  {
    value: 'ONGOING_PERIOD',
    label: 'ONGOING_PERIOD'
  },
  {
    value: 'TAGGED_PERIOD',
    label: 'TAGGED_PERIOD'
  },
  {
    value: 'MISSING_PERIOD',
    label: 'MISSING_PERIOD'
  },
  {
    value: 'NO_TAG',
    label: 'NO_TAG'
  }
]

export const systemStatusDown = [
  {
    value: 'ACTIVE',
    label: 'ACTIVE'
  },
  {
    value: 'CONNECTION_LOST',
    label: 'CONNECTION_LOST'
  },
  {
    value: 'NOT_EXISTING',
    label: 'NOT_EXISTING'
  },
  {
    value: 'NO_POWER',
    label: 'NO_POWER'
  },
  {
    value: 'DEFECT',
    label: 'DEFECT'
  },
  {
    value: 'DAMAGED',
    label: 'DAMAGED'
  },
  {
    value: 'INITIAL',
    label: 'INITIAL'
  },
  {
    value: 'DEINSTALLIERT',
    label: 'DEINSTALLIERT'
  }
]

export const systemLifecircle = [
  {
    value: 'PENDING',
    label: 'PENDING'
  },
  {
    value: 'PRODUCTIVE',
    label: 'PRODUCTIVE'
  },
  {
    value: 'TESTSYSTEM',
    label: 'TESTSYSTEM'
  },
  {
    value: 'DEV',
    label: 'DEV'
  },
  {
    value: 'RETIRED',
    label: 'RETIRED'
  }
]

export const colorLensClubDown = [
  {
    value: 'check',
    label: 'Prüfen'
  },
  {
    value: 'TBD',
    label: 'TBD'
  },
  {
    value: 'calibrated',
    label: 'Kalibriert'
  }
]

export const videoType = [
  { value: undefined, label: 'undefined' },
  { value: 'soccer', label: 'soccer' },
  { value: 'icehockey', label: 'icehockey' },
  { value: 'hockey', label: 'hockey' },
  { value: 'handball', label: 'handball' },
  { value: 'baseball', label: 'baseball' },
  { value: 'basketball', label: 'basketball' },
  { value: 'volleyball', label: 'volleyball' },
  { value: 'motoball', label: 'motoball' },
  { value: 'horses', label: 'horses' },
  { value: 'dressage', label: 'dressage' }, 
  { value: 'americanFootball', label: 'americanFootball' },
  { value: 'lacrosse', label: 'lacrosse' },
  { value: 'panorama', label: 'panorama' },
  { value: 'fullField', label: 'fullField' },
  { value: 'soccer_grid', label: 'soccer_grid' },
  { value: 'soccerTopView', label: 'soccerTopView' },
  { value: 'soccerAllPlayers', label: 'soccerAllPlayers' },
  { value: 'quarterBoxPanorama', label: 'quarterBoxPanorama' },
  { value: 'scoutingFeedSoccer', label: 'scoutingFeedSoccer' },
  { value: 'passthrough', label: 'passthrough' },
  { value: 'nonCompetitiveEvent', label: 'nonCompetitiveEvent' }
]

export const videoTypeOnlySport = [
  { value: '', label: '' },
  { value: 'soccer', label: 'soccer' },
  { value: 'icehockey', label: 'icehockey' },
  { value: 'hockey', label: 'hockey' },
  { value: 'handball', label: 'handball' },
  { value: 'baseball', label: 'baseball' },
  { value: 'basketball', label: 'basketball' },
  { value: 'volleyball', label: 'volleyball' },
  { value: 'motoball', label: 'motoball' },
  { value: 'horses', label: 'horses' },
  { value: 'dressage', label: 'dressage' },
  { value: 'americanFootball', label: 'americanFootball' },
  { value: 'lacrosse', label: 'lacrosse' }
]

export const outputTemplates = [
  { value: undefined, label: 'undefined' },
  { value: '360p', label: '360p 600' },
  { value: '720p', label: '720p 1800' },
  { value: '720p8000', label: '720p 8000' },
  { value: '1080p', label: '1080p 3500' },
  { value: '1080pMax', label: '1080p 6000' },
  { value: '1080p8000', label: '1080p 8000' },
  { value: '4k', label: '4k 8000' }
]

export const renderConfiguration = [
  { value: '', label: '' },
  { value: 'default', label: 'default' },
  { value: 'legacy', label: 'legacy' },
  { value: 'legacy', label: 'legacy' },
  { value: 'power', label: 'power' },
  { value: 't4-8', label: 't4-8' },
  { value: 't4-8-eu', label: 't4-8-eu' },
  { value: 't4-8-ww', label: 't4-8-ww' },
  { value: 't4-8-china', label: 't4-8-china' },
  { value: 'l4-8-eu', label: 'l4-8-eu' },
  { value: 'l4-8-us-west', label: 'l4-8-us-west' },
  { value: 'l4-8-us-east', label: 'l4-8-us-east' },
  { value: 'l4-16-eu', label: 'l4-16-eu' },
  { value: 'p100-8', label: 'p100-8' },
  { value: 'p100-16', label: 'p100-16' },
  { value: 'p100-8-eu', label: 'p100-8-eu' },
  { value: 'p100-16-eu', label: 'p100-16-eu' }
]

export const backendVersion = [
  { value: '', label: '' },
  { value: 'aisw-ww-prod', label: 'aisw-ww-prod' },
  { value: 'aisw-ww-dev', label: 'aisw-ww-dev' }
]

export const hardwarePlatform = [
  { value: '', label: '' },
  { value: 'K1', label: 'K1' },
  { value: 'K2', label: 'K2' },
  { value: 'K2M', label: 'K2M' },
  { value: 'K2X', label: 'K2X' },
  { value: 'K2-NF', label: 'K2-NF' },
  { value: 'K2-XF', label: 'K2-XF' },
  { value: 'K2-12V', label: 'K2-12V' },
  { value: 'ip', label: 'ip' },
  { value: 'qube', label: 'qube' }
]

export const videoFormat = [
  { value: '', label: '' },
  { value: 'K1', label: 'K1' },
  { value: 'K2', label: 'K2' },
  { value: 'K2M', label: 'K2M' },
  { value: 'K2X', label: 'K2X' },
  { value: 'IP', label: 'IP' },
  { value: 'MOBILEAPP', label: 'MOBILEAPP' },
  { value: 'EXTSTREAM', label: 'EXTSTREAM' }
]

export const videoProcessing = [
  { value: undefined, label: 'undefined' },
  { value: 'soccer', label: 'soccer' },
  { valie: 'soccer_analytics', label: 'soccer_analytics' },
  { value: 'icehockey', label: 'icehockey' },
  { value: 'hockey', label: 'hockey' },
  { value: 'handball', label: 'handball' },
  { value: 'baseball', label: 'baseball' },
  { value: 'basketball', label: 'basketball' },
  { value: 'volleyball', label: 'volleyball' },
  { value: 'motoball', label: 'motoball' },
  { value: 'americanFootball', label: 'americanFootball' },
  { value: 'lacrosse', label: 'lacrosse' },
  { value: 'horses', label: 'horses' },
  { value: 'dressage_group', label: 'dressage_group' },
  { value: 'show_jumping', label: 'show_jumping' },
  { value: 'dressage', label: 'dressage' },
  { value: 'waterpolo', label: 'waterpolo' },
  { value: 'panorama', label: 'panorama' },
  { value: 'fullField', label: 'fullField' },
  { value: 'soccerTopView', label: 'soccerTopView' },
  { value: 'soccerAllPlayers', label: 'soccerAllPlayers' },
  { value: 'quarterBoxPanorama', label: 'quarterBoxPanorama' },
  { value: 'scoutingFeedSoccer', label: 'scoutingFeedSoccer' },
  { value: 'scoutingFeedIcehockey', label: 'scoutingFeedIcehockey' },
  { value: 'passthrough', label: 'passthrough' },
  { value: 'soccerBeta', label: 'soccerBeta' },
  { value: 'handballBeta', label: 'handballBeta' },
  { value: 'soccer_grid', label: 'soccer_grid' }
]

export const eventModes = [
  { value: '', label: '' },
  { value: 'broadcast', label: 'broadcast' },
  { value: 'training', label: 'training' }
]

export const eventType = [
  { value: 'soccer', label: 'soccer' },
  { value: 'icehockey', label: 'icehockey' },
  { value: 'hockey', label: 'hockey' },
  { value: 'handball', label: 'handball' },
  { value: 'baseball', label: 'baseball' },
  { value: 'basketball', label: 'basketball' },
  { value: 'volleyball', label: 'volleyball' },
  { value: 'motoball', label: 'motoball' },
  { value: 'americanFootball', label: 'americanFootball' },
  { value: 'lacrosse', label: 'lacrosse' },
  { value: 'waterpolo', label: 'waterpolo' },
  { value: 'sailing', label: 'sailing' },
  { value: 'golf', label: 'golf' },
  { value: 'tabletennis', label: 'tabletennis' },
  { value: 'tennis', label: 'tennis' },
  { value: 'padeltennis', label: 'padeltennis' },
  { value: 'horses', label: 'horses' },
  { value: 'dressage', label: 'dressage' },
  { value: 'dressage_group', label: 'dressage_group' },
  { value: 'music', label: 'music' },
  { value: 'theater', label: 'theater' },
  { value: 'musical', label: 'musical' },
  { value: 'church', label: 'church' },
  { value: 'townhall', label: 'townhall' },
  { value: 'congress', label: 'congress' },
  { value: 'surveillance', label: 'surveillance' },
  { value: 'nonCompetitiveEvent', label: 'nonCompetitiveEvent' },
  { value: 'passthrough', label: 'passthroughDEP' }
]

export const contentType = [
  { value: '', label: '' },
  { value: 'broadcast', label: 'broadcast' },
  { value: 'training', label: 'training' },
  { value: 'test', label: 'test' }
]

export const priorityTenScale = [
  { label: '', value: '' },
  { label: 'Lowest', value: 0 },
  { label: 'Lower', value: 1 },
  { label: 'Low', value: 2 },
  { label: 'mediumLow', value: 3 },
  { label: 'medium', value: 4 },
  { label: 'mediumHigh', value: 5 },
  { label: 'high', value: 6 },
  { label: 'higher', value: 7 },
  { label: 'highest', value: 8 },
  { label: 'Critical', value: 9 },
  { label: 'MostCritical', value: 10 }
]

export const useStateFlow = [
  { value: 'default', label: 'default' },
  { value: 'urgent', label: 'urgent' },
  { value: 'srtStream', label: 'srtStream' },
  { value: 'rerender', label: 'rerender' },
  { value: 'externalBroadcast', label: 'externalBroadcast' },
  { value: 'singleIPStream', label: 'singleIPStream' },
  { value: 'qubeStream', label: 'qubeStream' },
  { value: 'cameraUploadTest', label: 'cameraUploadTest' }
]

export const videoStates = [
  { value: 'created', label: 'created', setOnlyOldState: ['done', 'created'] },
  { value: 'done', label: 'done', setOnlyOldState: ['created', 'done'] },
  { value: 'Check Camera', label: 'Check Camera' },
  { value: 'Create Asset', label: 'Create Asset' },
  { value: 'Start camera', label: 'Start camera' },
  { value: 'Awaiting Grid Stream', label: 'Awaiting Grid Stream' },
  { value: 'Produce clone', label: 'Produce clone' },
  { value: 'Prepare clone', label: 'Prepare clone' },
  { value: 'Mission running', label: 'Mission running' },
  { value: 'Eliminate Clone', label: 'Eliminate Clone', setOnlyOldState: [
    'created', 'done', 'Check Camera', 'Create Asset', 'Start camera', 'Awaiting Grid Stream','Produce clone', 'Prepare clone', 'Mission running', 'Eliminate Clone', 'Mean liveness',
    'scheduledExternalStream', 'awaitingExternalStream', 'liveExternalStream'
  ]},
  { value: 'Mean liveness', label: 'Mean liveness' },
  { value: 'scheduledExternalStream', label: 'scheduledExternalStream' },
  { value: 'awaitingExternalStream', label: 'awaitingExternalStream' },
  { value: 'liveExternalStream', label: 'liveExternalStream' }
]



export const archiveState = VideoArchiveStateConst.map((value=>{
  return { value: value, label: value }
}))

export const legalContractType = [
  { value: 'Sportförderer', label: 'Sportförderer' },
  { value: 'Lizenz', label: 'Lizenz' },
  { value: 'Direct Sales', label: 'Direct Sales' },
  { value: 'IntSales', label: 'Int. Sales' }
]