import React, { Component } from 'react'
import Dashboard from '../modules/dashboard/index'
import AISearch from '../modules/aiSearch/index'
import Club from '../modules/club/index'
import Team from '../modules/team/index'
import Cameras from '../modules/cameras/index'
import Cellular from '../modules/cellular/index'
import User from '../modules/user/index'
import Blacklist from '../modules/blacklist/index'
import Defaultlist from '../modules/defaultlist/index'
import Map from '../modules/map/index'
import Games from '../modules/games/index'
import GamesAddTest from '../modules/games/addtest'
import Clips from '../modules/clips/index'
// import Sponsor from '../modules/sponsor/index'
import CityImages from '../modules/cityImages/index'
// import Ad from '../modules/ad/index'
import Editing from '../modules/contentEditing/index'
import Infotool from '../modules/infotool/index'
import Cup from '../modules/cup/index'
import Region from '../modules/region/index'
import Generic from '../modules/generic/index'
import Contracts from '../modules/contract/index'
import footballClub from '../../icon/football-club.svg'

import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav'
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'

import 'font-awesome/css/font-awesome.min.css'
import './menu.css'
import Competition from '../modules/competition'
import License from '../modules/license'
import City from '../modules/city'
import League from '../modules/league'
import LogTabs from '../modules/real-time-log'
import BBULog from '../modules/bbu-log'
import Socialclips from '../modules/socialclips'

const navWidthCollapsed = 80
const navWidthExpanded = 256

class Menu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: 'dashboard',
      expanded: false
    }
  }

  /**
   * Menu History
   */
  componentDidMount() {
    const temp = window.location.pathname

    if (temp !== '/') {
      const temp2 = temp.slice(1)
      this.setState({ selected: temp2 })
    }
  }

  // Must be change!!!
  shouldComponentUpdate(nextProps, nextState) {
    return this.state.value !== nextState.value
  }

  onSelect = (selected) => {
    this.setState({ selected: selected })
  };

  onToggle = (expanded) => {
    this.setState({ expanded: expanded })
  };

  render() {
    const { selected } = this.state
    return (
      <Router>
        <Route
          render={({ location, history }) => (
            (this.props.swisscom || this.props.limitAdmin) ? (
              <>
                <SideNav
                  onSelect={(selected) => {
                    const to = '/' + selected
                    if (location.pathname !== to) {
                      history.push(to)
                    }
                  }}
                  onToggle={this.onToggle.bind(this)}
                >
                  <SideNav.Toggle />
                  <SideNav.Nav defaultSelected={selected}>
                    <NavItem eventKey='dashboard'>
                      <NavIcon>
                        <Link to='/'>
                          <i className='fa fa-fw fa-home' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Startseite</NavText>
                    </NavItem>
                    <NavItem eventKey='games/overview'>
                      <NavIcon>
                        <i className='fa fa-soccer-ball-o' style={{ fontSize: '1.75em' }} />
                      </NavIcon>
                      <NavText>Spiele</NavText>
                    </NavItem>
                    <NavItem eventKey='games/test'>
                      <NavIcon>
                        <Link to='/games/test'>
                          <i className='fa fa-soccer-ball-o' style={{ fontSize: '1.75em' }} />
                          <div className='gameTest-ball'>test</div>
                        </Link>
                      </NavIcon>
                      <NavText>Test hinzufügen</NavText>
                    </NavItem>
                    <NavItem eventKey='cameras'>
                      <NavIcon>
                        <Link to='/cameras'>
                          <i className='fa fa-fw fa-camera' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Kameras</NavText>
                    </NavItem>
                  </SideNav.Nav>
                  <div className='menu' />
                </SideNav>
                <main
                  className='mainContent'
                  style={{ paddingLeft: this.state.expanded ? navWidthExpanded : navWidthCollapsed }}
                >
                  <Route path='/' exact component={(props) => <Dashboard swisscom={this.props.swisscom} />} />
                  <Route path='/search' exact component={(props) => <AISearch/>} />
                  <Route path='/dashboard' component={(props) => <Dashboard swisscom={this.props.swisscom} />} />
                  <Route
                    path='/games/overview'
                    exact
                    component={(props) => (
                      <Games headingText='Spiele / Übersicht' notification={this.props.notification} />
                    )}
                  />
                  <Route
                    path='/games/overview/:rows'
                    component={(props) => (
                      <Games {...props} headingText='Spiele / Übersicht' notification={this.props.notification} />
                    )}
                  />
                  <Route
                    path='/games/test'
                    component={(props) => <GamesAddTest headingText='Spiele / Test hinzufügen' />}
                  />
                  <Route
                    exact
                    path='/cameras'
                    component={(props) => <Cameras headingText='Kameras' notification={this.props.notification} />}
                  />
                  <Route
                    path='/cameras/:rows'
                    component={(props) => (
                      <Cameras {...props} headingText='Kameras' notification={this.props.notification} />
                    )}
                  />

                </main>
              </>
            ) : (
              <>
                <SideNav
                  onSelect={(selected) => {
                    const to = '/' + selected
                    if (location.pathname !== to) {
                      history.push(to)
                    }
                  }}
                  onToggle={this.onToggle.bind(this)}
                >
                  <SideNav.Toggle />
                  <SideNav.Nav defaultSelected={selected}>
                    <NavItem eventKey='dashboard'>
                      <NavIcon>
                        <Link to='/'>
                          <i className='fa fa-fw fa-home' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Startseite</NavText>
                    </NavItem>

                    <NavItem eventKey='search'>
                      <NavIcon>
                        <Link to='/search'>
                          <i className='fa fa-search' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>AI Search</NavText>
                    </NavItem>

                    <NavItem eventKey='map'>
                      <NavIcon>
                        <Link to='/map'>
                          <i className='fa fa-fw fa-map' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Karte</NavText>
                    </NavItem>

                    <NavItem eventKey='club'>
                      <NavIcon>
                        <Link to='/club'>
                          <img src={footballClub} alt='clubs' style={{ height: '31.5px', marginTop: '8px' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Vereine</NavText>
                    </NavItem>

                    <NavItem eventKey='team'>
                      <NavIcon>
                        <Link to='/team'>
                          <i className='fa fa-bus' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Teams</NavText>
                    </NavItem>

                    <NavItem eventKey='competition'>
                      <NavIcon>
                        <Link to='/competition'>
                          <i className='fa fa-star' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Competition</NavText>
                    </NavItem>

                    <NavItem eventKey='league'>
                      <NavIcon>
                        <Link to='/league'>
                          <i className='fa fa-compass' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>League</NavText>
                    </NavItem>

                    <NavItem eventKey='infotool'>
                      <NavIcon>
                        <Link to='/infotool'>
                          <i className='fa fa-binoculars' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Infotool</NavText>
                    </NavItem>

                    <NavItem eventKey='games/overview'>
                      <NavIcon>
                        <i className='fa fa-soccer-ball-o' style={{ fontSize: '1.75em' }} />
                      </NavIcon>
                      <NavText>Spiele</NavText>
                    </NavItem>

                    <NavItem eventKey='games/test'>
                      <NavIcon>
                        <Link to='/games/test'>
                          <i className='fa fa-soccer-ball-o' style={{ fontSize: '1.75em' }} />
                          <div className='gameTest-ball'>test</div>
                        </Link>
                      </NavIcon>
                      <NavText>Test hinzufügen</NavText>
                    </NavItem>

                    <NavItem eventKey='clips'>
                      <NavIcon>
                        <Link to='/clips'>
                          <i className='fa fa-fw fa-film' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Highlight Clips</NavText>
                    </NavItem>

                    <NavItem eventKey='cameras'>
                      <NavIcon>
                        <Link to='/cameras'>
                          <i className='fa fa-fw fa-camera' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Kameras</NavText>
                    </NavItem>

                    {/*
                  <NavItem eventKey="cellular">
                    <NavIcon>
                      <i className="fa fa-fw fa-mobile" style={{ fontSize: '1.75em' }} />
                    </NavIcon>
                    <NavText>
                      Celluar Module
                  </NavText>
                  </NavItem>
                */}

                    <NavItem eventKey='editing/clip'>
                      <NavIcon>
                        <Link to='editing/clip'>
                          {' '}
                          <i className='fa fa-list-alt' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Clips</NavText>
                    </NavItem>

                    <NavItem eventKey='/editing/header'>
                      <NavIcon>
                        <Link to='/editing/header'>
                          {' '}
                          <i className='fa fa-list-alt' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Header</NavText>
                    </NavItem>

                    {this.props.user.role.find(a => a === 'useradmin') && (
                      <NavItem eventKey='user'>
                        <NavIcon>
                          <Link to='/user'>
                            <i className='fa fa-fw fa-users' style={{ fontSize: '1.75em' }} />
                          </Link>
                        </NavIcon>
                        <NavText>Benutzer</NavText>
                      </NavItem>
                    )}

                    <NavItem eventKey='blacklist'>
                      <NavIcon>
                        <Link to='/blacklist'>
                          <i className='fa fa-ban' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Blacklist</NavText>
                    </NavItem>

                    <NavItem eventKey='defaultlist'>
                      <NavIcon>
                        <Link to='/defaultlist'>
                          <i className='fa fa-bandcamp' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Defaultlist</NavText>
                    </NavItem>

                    <NavItem eventKey='city'>
                      <NavIcon>
                        <Link to='/city'>
                          <i className='fa fa-map-marker' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Cities</NavText>
                    </NavItem>

                    {/* <NavItem eventKey='cityImages'>
                      <NavIcon>
                        <Link to='/cityImages'>
                          <i className='fa fa-picture-o' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>City Images</NavText>
                    </NavItem> */}

                    <NavItem eventKey='advertisement'>
                      <NavIcon>
                        <Link to='/advertisement'>
                          <i className='fa fa-gear' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Advertisement</NavText>
                    </NavItem>

                    {/* <NavItem eventKey='ad'>
                      <NavIcon>
                        <Link to='/ad'>
                          <i className='fa fa-gears' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Generic AD</NavText>
                    </NavItem> */}

                    <NavItem eventKey='region'>
                      <NavIcon>
                        <Link to='/region'>
                          <i className='fa fa-globe' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Region</NavText>
                    </NavItem>

                    <NavItem eventKey='socialclips'>
                      <NavIcon>
                        <Link to='/socialclips'>
                          <i className='fa fa-film' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Socialclips</NavText>
                    </NavItem>

                    <NavItem eventKey='cup'>
                      <NavIcon>
                        <Link to='/cup'>
                          <i className='fa fa-trophy' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Cup</NavText>
                    </NavItem>

                    <NavItem eventKey='contracts'>
                      <NavIcon>
                        <Link to='/contracts'>
                          <i className='fa fa-object-group' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>Contracts</NavText>
                    </NavItem>

                    <NavItem eventKey='logs'>
                      <NavIcon>
                        <Link to='/logs'>
                          <i className='fa fa-book' style={{ fontSize: '1.75em' }} />
                        </Link>
                      </NavIcon>
                      <NavText>LOGS</NavText>
                    </NavItem>

                  </SideNav.Nav>
                  <div className='menu' />
                </SideNav>
                <main
                  className='mainContent'
                  style={{ paddingLeft: this.state.expanded ? navWidthExpanded : navWidthCollapsed }}
                >
                  <Route path='/' exact component={(props) => <Dashboard />} />
                  <Route path='/dashboard' component={(props) => <Dashboard />} />
                  <Route path='/search' exact component={(props) => <AISearch/>} />
                  <Route path='/map' component={(props) => <Map headingText='Karte' />} />
                  <Route path='/club' exact component={(props) => <Club {...props} headingText='Vereine' />} />
                  <Route path='/club/:rows' component={(props) => <Club {...props} headingText='Vereine' />} />

                  <Route path='/Contracts' exact component={(props) => <Contracts {...props} headingText='Contracts' />} />
                  <Route path='/Contracts/:rows' component={(props) => <Contracts {...props} headingText='Contracts' />} />
                  <Route path='/UserContracts/:Name/:UserId' component={(props) => <Contracts {...props} headingText={`Contracts of ${props.match.params.Name}`} />} />

                  <Route path='/team' exact component={(props) => <Team {...props} headingText='Teams' />} />
                  <Route path='/team/:rows' component={(props) => <Team {...props} headingText='Teams' />} />
                  <Route path='/competition' exact component={(props) => <Competition {...props} headingText='Competitions' />} />
                  <Route path='/competition/:rows' component={(props) => <Competition {...props} headingText='Competitions' />} />

                  <Route path='/league' exact component={(props) => <League {...props} headingText='League' />} />
                  <Route path='/league/:rows' component={(props) => <League {...props} headingText='League' />} />

                  <Route
                    path='/games/overview'
                    exact
                    component={(props) => (
                      <Games headingText='Spiele / Übersicht' notification={this.props.notification} />
                    )}
                  />
                  <Route
                    path='/games/overview/:rows'
                    component={(props) => (
                      <Games {...props} headingText='Spiele / Übersicht' notification={this.props.notification} />
                    )}
                  />
                  <Route
                    path='/games/test'
                    component={(props) => <GamesAddTest headingText='Spiele / Tests' />}
                  />
                  <Route path='/clips' component={(props) => <Clips headingText='Highlight Clips' />} />
                  <Route
                    exact
                    path='/cameras'
                    component={(props) => <Cameras headingText='Kameras' notification={this.props.notification} />}
                  />
                  <Route
                    path='/cameras/:rows'
                    component={(props) => (
                      <Cameras {...props} headingText='Kameras' notification={this.props.notification} />
                    )}
                  />
                  <Route path='/cellular' component={(props) => <Cellular headingText='Cellular Module' />} />
                  {/* <Route
                    path='/sponsor'
                    exact
                    component={(props) => <Sponsor headingText='Sponsoring' />}
                    notification={this.props.notification}
                  />
                  <Route
                    path='/sponsor/:rows'
                    render={(props) => <Sponsor {...props} notification={this.props.notification} />}
                    /> */}
                  {this.props.user.role.find(a => a === 'useradmin') && <Route path='/user' exact component={(props) => <User headingText='Benutzer' />} />}
                  {this.props.user.role.find(a => a === 'useradmin') && <Route path='/user/:rows' component={(props) => <User {...props} headingText='Benutzer' />} />}

                  <Route path='/licenses/:Name/:UserId' component={(props) => <License {...props} headingText={`Licenses of ${props.match.params.Name}`} />} />
                  <Route path='/licenses/' exact={true} component={(props) => <License {...props} headingText={`Licenses`} />} />
                  <Route path='/trainer/' component={(props) => <License {...props} licenseType='TrainerSubscription' headingText={`TrainerSubscriptions`} />} />

                  <Route path='/blacklist' exact component={(props) => <Blacklist {...props} headingText='Blacklist' />} />
                  <Route path='/blacklist/:rows' component={(props) => <Blacklist {...props} headingText='Blacklist' />} />
                  <Route path='/defaultlist' exact component={(props) => <Defaultlist {...props} headingText='Defaultlist' />} />
                  <Route path='/defaultlist/:rows' component={(props) => <Defaultlist {...props} headingText='Defaultlist' />} />
                  <Route path='/city' component={(props) => <City {...props} headingText='Cities' />} />
                  <Route path='/cityImages' component={(props) => <CityImages {...props} headingText='City Images' />} />
                  {/* <Route path='/ad' exact component={(props) => <Ad {...props} headingText='Ad' />} />
                  <Route path='/ad/:rows' component={(props) => <Ad {...props} headingText='Ad' />} /> */}
                  <Route path='/advertisement' exact component={(props) => <Generic {...props} headingText='Advertisement' />} />
                  <Route path='/advertisement/:rows' component={(props) => <Generic {...props} headingText='Advertisement' />} />
                  <Route path='/editing/clip' exact component={(props) => <Editing headingText='Redaktion' menu={0} />} />
                  <Route path='/editing/clip/:rows' component={(props) => <Editing {...props} headingText='Redaktion' menu={0} />} />
                  <Route path='/editing/header' exact component={(props) => <Editing headingText='Redaktion' menu={1} />} />
                  <Route path='/editing/header/:rows' component={(props) => <Editing {...props} headingText='Redaktion' menu={1} />} />
                  <Route path='/infotool' exact component={(props) => <Infotool {...props} headingText='Infotool' />} />
                  <Route path='/cup' exact component={(props) => <Cup {...props} headingText='Cup' />} />
                  <Route path='/cup/:rows' component={(props) => <Cup {...props} headingText='Cup' />} />
                  <Route path='/region' exact component={(props) => <Region {...props} headingText='Region' />} />
                  <Route path='/region/:rows' component={(props) => <Region {...props} headingText='Region' />} />
                  <Route path='/socialclips' exact component={(props) => <Socialclips {...props} headingText='Socialclips' />} />
                  <Route path='/socialclips/:rows' component={(props) => <Socialclips {...props} headingText='Socialclips' />} />
                  <Route path='/logs' component={(props) => <LogTabs {...props} headingText='' />}></Route>
                  <Route path='/logs/:type/:from/:till' component={(props) => <BBULog {...props} headingText='BBU Logs' />}></Route>
                </main>
              </>
            )

          )}
        />
      </Router>
    )
  }
}

export default Menu
