import React, { Component } from 'react'
import HeadText from '../static/tableHeading'
import Grid from '@material-ui/core/Grid'
import Table from '../static/table'

import { storageLayerTableInfo } from '../static/config/storageLayerTableConfig'
import { getSocialClipsTimeByCitys, getSocialClipsTimeByClubs, getSocialClipsTimeByID, getSocialClipsTimeByVideos, postSocialClipsManipulate } from '../../api/api-v2-admin'
import DatePicker from 'react-datepicker'
import { Button, Paper, TextField } from '@material-ui/core'

const config = {
  tableName: 'socialclips',
  tableUrl: '/socialclips/',
  tableDialogTitle: 'Socialclips',
  tableToolbar: true,
  tableAdd: false,
  columns: [
    { name: 'RowKey', title: 'RowKey', width: 200, toEditable: false },
    { name: 'videoPrev', title: 'videoPrev', width: 100, disableDialog: true },
    { name: 'videoId', title: 'videoId', width: 100, disableDialog: true },
    { name: 'likes', title: 'likes', width: 80, disableDialog: true },
    { name: 'dislikes', title: 'dislikes', width: 80 },
    { name: 'upVote', title: 'Hoch Voten',  width: 160, disableDialog: true, toCreate: true },
    { name: 'downVote', title: 'Runter Voten',  width: 160, disableDialog: true, toCreate: true },
    { name: 'url', title: 'url', width: 280 },
    { name: 'timestamp', title: 'timestamp', width: 200 },
    ...storageLayerTableInfo
  ],
  defaultHiddenColumnNames: [{ label: 'Alles', value: [''] }]
}

class Socialclips extends Component {
  constructor (props) {
    super(props)

    this.state = {
      rows: [],
      tempId: '0',
      check: false,
      loadingCall: false,
      date: {
        from: new Date(new Date().getFullYear(), 0, 1),
        to: new Date()
      },
      tab: 0,
      socialId: '',
      clubId: '',
      cityId: '',
      videoId: ''
    }
  }

  handleId = (id) => {
    if (id != null) {
      this.setState({ tempId: id })
    }
  }

  /**
   * Api-call get all GenericList
   */
  componentDidMount () {
    this.handleGetCallById()
  }

  handleGetCallById = () => {
    const id = this.state.socialId.length > 0 ? this.state.socialId : '302645-69267ce9185a38cc0a0a8b99717d170b'
    getSocialClipsTimeByID(id).then((res) => {
      if (res.status === 200) {
        let rows = [res.data]
        if (res.data === '') {
          rows = []
        }
        this.handleGetList(rows)
      }
    })
  }

  handleGetCallByClub = async () => {
    this.setState({loadingCall: true })
    let newData = []

    let body = { 
      from: new Date(this.state.date.from).toISOString(),
      to: new Date(this.state.date.to).toISOString()
    }

    await getSocialClipsTimeByClubs({ ...body, ids: [this.state.clubId]  }).then(res => {
      if(res.status === 201) {
        newData = newData.concat(res.data)
      }
    })

    newData = this.filterDuplicates(newData, 'RowKey')
    this.handleGetList(newData)
    this.setState({ loadingCall: false })
  }

  handleGetCallByCity = async () => {
    this.setState({loadingCall: true })
    let newData = []

    let body = { 
      from: new Date(this.state.date.from).toISOString(),
      to: new Date(this.state.date.to).toISOString()
    }

    await getSocialClipsTimeByCitys({ ...body, ids: [this.state.cityId]  }).then(res => {
      if(res.status === 201) {
        newData = newData.concat(res.data)
      }
    })

    newData = this.filterDuplicates(newData, 'RowKey')
    this.handleGetList(newData)
    this.setState({ loadingCall: false })
  }

  handleGetCallByVideoId = async () => {
    this.setState({loadingCall: true })
    let newData = []

    if(this.state.videoId === '') return

    await getSocialClipsTimeByVideos({ ids: [this.state.videoId]  }).then(res => {
      if(res.status === 201) {
        newData = newData.concat(res.data)
      }
    })

    newData = this.filterDuplicates(newData, 'RowKey')
    this.handleGetList(newData)
    this.setState({ loadingCall: false })
  }


  filterDuplicates(array, key) {
    const seen = new Set()
    return array.filter(item => {
        const value = item[key]
        if (seen.has(value)) {
            return false; // Duplicate found
        }
        seen.add(value);
        return true; // Unique item
    })
  }

  voteByRowKey = async (id, mod) => {
    await postSocialClipsManipulate({
      RowKey: id,
      mod: mod
    })

    const newRows = this.state.rows.map(row => {
      if (row.RowKey === id) {
        if (mod > 0) {
          row.likes += mod
        } else {
          row.dislikes -= mod
        }
      }
      return row
    })
    this.handleGetList(newRows)
  }

  handleGetList = (res) => {
    res.sort((a, b) => {
      return Number(a.RowKey) - Number(b.RowKey)
    }).map((row, index) => {
      row.tableId = index

      row.likes = row.likes ? row.likes : 0
      row.dislikes = row.dislikes ? row.dislikes : 0

      row.upVote = (
        <Button
          onClick={() => this.voteByRowKey(row.RowKey, 10)}
          color='secondary'
          variant='contained'
          className='camera-btn'
          style={{ backgroundColor: '#fff !important', height: '24px', fontSize: '12px' }}
        >
          Liken +10
        </Button>
      )
      row.downVote = (
        <Button
          onClick={() => this.voteByRowKey(row.RowKey, -10)}
          color='secondary'
          variant='contained'
          className='camera-btn'
          style={{ backgroundColor: '#fff !important', height: '24px', fontSize: '12px' }}
        >
          Disliken -10
        </Button>
      )

      row.videoPrev = (
        <video src={row.url} style={{ height: '100%', width: '100%' }} controls>
          <track kind="captions" />
        </video>
      )

      return row
    })
    this.setState({
      rows: res
    })
  }

  handleSetFromDate = (date, state) => {
    this.setState(prevState => ({ date: { ...prevState.date, [state]: new Date(date) } }))
  }

  render () {
    return (
      <div>
        <HeadText text={this.props.headingText} />
        <Paper>
          <div style={{ display: 'flex', margin: '10px 0', padding: '4px', flexDirection: 'column' }}>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Button onClick={() => this.setState({ tab: 0 })} color='primary' variant='outlined'>
                Socialclip ID
              </Button>
              <Button onClick={() => this.setState({ tab: 3 })} color='primary' variant='outlined'>
                Video ID
              </Button>
              <Button onClick={() => this.setState({ tab: 1 })} color='primary' variant='outlined'>
                Club ID
              </Button>
              <Button onClick={() => this.setState({ tab: 2 })} color='primary' variant='outlined'>
                City ID
              </Button>
            </div>
            {this.state.tab === 0 && (
              <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0'  }}>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <TextField
                    className='textField-table'
                    style={{ width: '340px' }}
                    placeholder='ID'
                    value={this.state.socialId}
                    onChange={(event) => { this.setState({ socialId: event.target.value }) }}
                    margin='none'
                  />
                  <Button onClick={() => this.handleGetCallById()} color='primary' autoFocus>
                    suchen
                  </Button>
                </div>
              </div>
            )}

            {this.state.tab === 1 && (
              <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <TextField
                    className='textField-table'
                    placeholder='Club ID'
                    value={this.state.clubId}
                    onChange={(event) => { this.setState({ clubId: event.target.value }) }}
                    margin='none'
                  />
                  <DatePicker dateFormat='dd.MM.yyyy' selected={this.state.date.from} onChange={(date) => this.handleSetFromDate(date, 'from')} />
                  <div>bis</div>
                  <DatePicker dateFormat='dd.MM.yyyy' selected={this.state.date.to} onChange={(date) => this.handleSetFromDate(date, 'to')} />
                  <Button onClick={() => this.handleGetCallByClub()} color='primary' autoFocus>
                    suchen
                  </Button>
                </div>
              </div>
            )}

            {this.state.tab === 2 && (
              <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <TextField
                    className='textField-table'
                    placeholder='City ID'
                    value={this.state.cityId}
                    onChange={(event) => { this.setState({ cityId: event.target.value }) }}
                    margin='none'
                  />
                  <DatePicker dateFormat='dd.MM.yyyy' selected={this.state.date.from} onChange={(date) => this.handleSetFromDate(date, 'from')} />
                  <div>bis</div>
                  <DatePicker dateFormat='dd.MM.yyyy' selected={this.state.date.to} onChange={(date) => this.handleSetFromDate(date, 'to')} />
                  <Button onClick={() => this.handleGetCallByCity()} color='primary' autoFocus>
                    suchen
                  </Button>
                </div>
              </div>
            )}

            {this.state.tab === 3 && (
              <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <TextField
                    className='textField-table'
                    placeholder='Video ID'
                    value={this.state.videoId}
                    onChange={(event) => { this.setState({ videoId: event.target.value }) }}
                    margin='none'
                  />
                  <Button onClick={() => this.handleGetCallByVideoId()} color='primary' autoFocus>
                    suchen
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Paper>
  

        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Table
              resetData={this.handleGetCall}
              onSelectId={this.handleId}
              tableConfig={config}
              tableRows={this.state.rows}
              // handleEditRow={this.handleEditColum}
              loadingCall={this.state.loadingCall}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Socialclips
