import axios from 'axios'
import * as rax from 'retry-axios'

import { getAuthorization } from './helper'

rax.attach()

export const api_admin_v2 = `https://europe-west1-aisw-ww-prod.cloudfunctions.net/api_admin_v2`

export const mergeTeam = async (from, to) => {
  return axios.get(`${api_admin_v2}/api_admin_v2/team/merge/${from}/${to}`, { headers: { Authorization: await getAuthorization() } })
}

export const mergeContract = async (from, to) => {
  return axios.get(`${api_admin_v2}/api_admin_v2/contract/merge/${from}/${to}`, { headers: { Authorization: await getAuthorization() } })
}

export const getCameraCornerPointsAll = async (cameraId) => {
  return axios.get(`${api_admin_v2}/camera/cornerpoints/${cameraId}`,  { headers: { Authorization: await getAuthorization() } })
}


/**
 * Same data as in api-cloudlog but also for contractOwner
 */
export const getCameraCloudlog = async (rowkey) => {
  return axios.get(api_admin_v2 + '/Camera/logs/' + rowkey, { headers: { Authorization: await getAuthorization() } })
}

/**
 * Same data as in api-cloudlog but also for contractOwner
 */
export const getCameraCloudlogFromTo = async (rowkey, from, to) => {
  return axios.get(api_admin_v2 + '/Camera/logs/' + rowkey + '/' + from + '/' + to, { headers: { Authorization: await getAuthorization() } })
}

// social clips
/*export const getSocialClipById = async (id) => {
  return axios.get(`${api_admin_v2}/social-clip/get/${id}`,  { headers: { Authorization: await getAuthorization() } })
}*/

export const getSocialClipsBulk = async () => {
  return axios.get(`${api_admin_v2}/social-clip/get/bulk`,  { headers: { Authorization: await getAuthorization() } })
}

export const getSocialClipsList = async () => {
  return axios.get(`${api_admin_v2}/social-clip/list`,  { headers: { Authorization: await getAuthorization() } })
}





export const getSocialClipsBulkByClubs = async (body) => {
  return axios.post(`${api_admin_v2}/social-clip/get/clubs/bulk`, body,{ headers: { Authorization: await getAuthorization() } })
}

export const getSocialClipsBulkByCitys = async (body) => {
  return axios.post(`${api_admin_v2}/social-clip/get/city/bulk`, body,{ headers: { Authorization: await getAuthorization() } })
}


export const getSocialClipsTimeByClubs = async (body) => {
  return axios.post(`${api_admin_v2}/social-clip/get/clubs/time`, body,{ headers: { Authorization: await getAuthorization() } })
}

export const getSocialClipsTimeByCitys = async (body) => {
  return axios.post(`${api_admin_v2}/social-clip/get/city/time`, body,{ headers: { Authorization: await getAuthorization() } })
}

export const getSocialClipsTimeByID = async (cameraId) => {
  return axios.get(`${api_admin_v2}/social-clip/get/${cameraId}`,  { headers: { Authorization: await getAuthorization() } })
}

export const postSocialClipsManipulate = async (body) => {
  return axios.post(`${api_admin_v2}/social-clip/manipulate`, body,{ headers: { Authorization: await getAuthorization() } })
}

export const getSocialClipsTimeByVideos = async (body) => {
  return axios.post(`${api_admin_v2}/social-clip/get/videos/bulk`, body,{ headers: { Authorization: await getAuthorization() } })
}

// calibration
export const setCameraCalibration = async (body) => {
  return axios.put(`${api_admin_v2}/calibration`, body,{ headers: { Authorization: await getAuthorization() } })
}

export const getCameraCalibrationList = async (cameraId) => {
  return axios.get(`${api_admin_v2}/calibration/${cameraId}`,  { headers: { Authorization: await getAuthorization() } })
}
