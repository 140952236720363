import React, { Component } from 'react'
import Button from '@material-ui/core/Button'

import { getCameraCalibrationList, setCameraCalibration } from '../../../api/api-v2-admin'
import { Input } from '@material-ui/core'

import 'react-select/dist/react-select.css'
import '../game.css'
import AiswSpinner from '../../../common/AiswSpinner'

class QualityRecording extends Component {
  constructor (props) {
    super(props)

    const date = new Date()
    date.setMinutes(date.getMinutes() + 5)

    this.state = {
      loading: false,
      inputValue: '843',
      duration: 1,
      videoList: [],
      noVideos: false,
      setReload: false,
    }
  }

  /*reloadTimer = () => {
    console.log('reloadTimer', this.state.setReload)
    if(this.state.setReload) {
      setTimeout(() => {
        this.getCameraCalibrationList(this.state.inputValue).then((res) => {
          if(res.status === 200) {
            let checkCreate = false
            res.data.map((vid) => {
              console.log(vid)
              checkCreate = res.data.includes(String(res.data))
              if(checkCreate) {
                checkCreate = true
              }
            })

            if(checkCreate) {
              this.setState({
                setReload: false,
                videoList: res.data
              })
            } else {
              this.reloadTimer()
            }
          }
        })
      }, 1000)
    }
  }*/

  handleCreateQualiRec  = async () => {
    const camId = this.state.inputValue
    this.setState({
      loading: true
    })

    const body = {
      'cameraId': camId,
      'duration': 1
    }

    try {
      const res = await setCameraCalibration(body)
     
      this.setState({
        loading: false,
        createVideoId: res.data.id,
        setReload: true
      })/*.then(() => {
        console.log('reload')
        this.reloadTimer()
      })*/
    } catch (error) {
      console.error(error)
      this.setState({
        loading: false
      })
    }
  }

  getAllQualiVideos = async () => {
    const camId = this.state.inputValue
    this.setState({
      videoList: [],
      noVideos: false,
      loading: true
    })

    try {
      await getCameraCalibrationList(camId).then((res) => {
        if(res.status === 200) {
          this.setState({
            loading: false,
            videoList: res.data,
            noVideos: res.data.length === 0
          })
        }
      })
    } catch (error) {
      console.error(error)
      this.setState({
        loading: false
      })
    }
  }

  render () {
    return (
      <div>
        <form className='game-addtest' noValidate style={{ paddingTop: 10, paddingBottom: 10, display: 'flex', gap: 10, justifyContent: 'space-between' }}>
          <div  style={{ display: 'flex', gap: 10 }} >
            <Input 
              style={{ minWidth: '160px' }}
              placeholder='Kamera ID'
              value={this.state.inputValue}
              onChange={(e) => {
                const value = e.target.value.trim()
                if(value.match(/^[0-9]+$/) != null) {
                  this.setState({ inputValue: e.target.value.trim() })
                } else if (value.length === 0) {
                  this.setState({ inputValue: '' })
                }
              }}
            />
            <Button disabled={this.state.inputValue.length === 0} onClick={this.getAllQualiVideos} className='btn' color='secondary' variant='contained' autoFocus>
              Alle Videoaufnahme zu Kamera Anzeigen
            </Button>
          </div>

          <Button disabled={this.state.inputValue.length === 0} onClick={this.handleCreateQualiRec} className='btn' color='primary' variant='contained'>
            Neue Videoaufnahme Anlegen
          </Button>
        </form>

        <div style={{ display: 'flex', padding: 18, flexDirection: 'column', flexWrap: 'wrap', overflow: 'auto' }}>


        {this.state.loading ? <div style={ { height: '140px' } }><AiswSpinner className='loadingSpinner-userManagment' /></div> : (null)}


        <a href="vlc://https://mystreamurl">open vlc</a>

          {!this.state.loading && this.state.videoList.length <= 0 ?(
            <div style={{ maxWidth: '880px', display: 'flex', gap: 10, flexDirection: 'column' }}>

              <div>Video Liste</div>

              {this.state.createVideoId ? (<div style={{fontSize: '24px',fontWeight: 'bold', margin: '20px 0', color: '#646464' }}>Neu angelegte Aufnahme ID: {this.state.createVideoId}</div>) : (null)}

              <div style={{ padding: 14, border: '1px solid #ccc',  borderRadius: 4, position: 'relative' }}>
                {this.state.noVideos ? ('Keine Videos vorhanden') : ('Noch keine Liste geladen')}
              </div>

            </div>
             
            ) : (

            <div style={{ maxWidth: '880px', display: 'flex', gap: 10, flexDirection: 'column' }}> 
            <div>Video Liste</div>

              {this.state.createVideoId ? (<div style={{fontSize: '24px',fontWeight: 'bold', margin: '20px 0', color: '#646464' }}>Neu angelegte Aufnahme ID: {this.state.createVideoId}</div>) : (null)}

              {this.state.videoList.map((videoUri, index) => {
                const checkCreate = videoUri.includes(String(this.state.createVideoId))
                return (
                  <div key={videoUri} style={{ padding: 14, border: '1px solid #ccc',  borderRadius: 4, position: 'relative', background: checkCreate ? 'rgb(255 229 229)' : null}}>
                    <div style={{ position: 'absolute', marginLeft: '-14px',marginTop: '-14px', background: 'rgb(208 208 208)', width: '18px', height: '18px', textAlign: 'center', borderRadius: '4px 0px 10px 0px', color: '#686868' }}>{index +1}</div>
                    <div style={{ fontSize: '14px', background: '#rgb(246 246 246)', borderRadius: '4px', padding: '4px', marginTop: '10px' }}>{videoUri}</div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default QualityRecording
