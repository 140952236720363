import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'

import { TimeRange, TimeSeries } from 'pondjs'
import DatePicker from 'react-datepicker'
import { ChartContainer, ChartRow, Charts, LineChart, Resizable, styler, YAxis } from 'react-timeseries-charts'
import { getCameraCloudlogFromTo } from '../../../../api/api-v2-admin'
import { circularProgressCenter } from '../../../static/loadingProgress'

const style = styler([
  { key: 'value', color: '#FF3517', opacity: 1, width: 2 }
])

class Status extends Component {
  constructor(props) {
    super(props)

    const now = new Date()
    const from = new Date().setDate(now.getDate() - 7)

    this.state = {
      series: [],
      rows: [],
      lastToken: '',
      range: null,
      loadingCall: true,
      moreBtn: true,
      from: from,
      to: now.getTime()
    }

    this.handleFullCall = this.handleFullCall.bind(this)
  }

  async componentDidMount () {
    await this.handleGetCall()
  }

  async handleGetCall () {
    const { from, to } = this.state
    const logs = await getCameraCloudlogFromTo(this.props.rowinfo.RowKey, from, to)
    this.handleGetMatchList(logs.data, true)
  }

  handleGetMatchList (res, checkLast) {
    const points = []
    const temp = res.sort((a, b) => {
      return new Date(a.timestamp) - new Date(b.timestamp)
    })

    var today = new Date()
    var priorDate = new Date().setDate(today.getDate() - 30)
    var tempValue

    if(res.length === 0) {
      this.setState({
        loadingCall: false
      })
      return
    }

    temp.forEach((n) => {
      const time = new Date(n.timestamp).getTime()

      if(n.data === undefined) return

      if (n.data.currentTask === 'uploading') {
        if (tempValue) {
          points.push([time, tempValue])
        }
        tempValue = 4
        points.push([time, 4])
      } else if (n.data.currentTask === 'capturing') {
        if (tempValue) {
          points.push([time, tempValue])
        }
        tempValue = 5
        points.push([time, 5])
      } else if (n.data.currentTask === 'idle') {
        if (tempValue) {
          points.push([time, tempValue])
        }
        tempValue = 2
        points.push([time, 2])
      } else if (n.data.currentTask === 'unknown') {
        if (tempValue) {
          points.push([time, tempValue])
        }
        tempValue = 1
        points.push([time, 1])
      } else if (n.data.currentTask === 'updating') {
        if (tempValue) {
          points.push([time, tempValue])
        }
        tempValue = 3
        points.push([time, 3])
      }
    })

    if (points.length > 0 && points[points.length - 1][1]) {
      points.push([new Date(), points[points.length - 1][1]])
    }

    if (points.length > 0) {
      const series = new TimeSeries({
        name: 'teleState',
        columns: ['time', 'value'],
        points
      })

      const range = new TimeRange(
        new Date(priorDate),
        new Date()
      )
     
      this.setState({
        series: series,
        range: range,
        rows: res,
        loadingCall: false
      })
      
    } else {
      this.setState({
        loadingCall: false
      })
    }
  }

  renderNoData() {
    return (
      <Typography component='div'>
        <div style={{ display: 'flex' }} className='btn-cont'>
          <div><DatePicker dateFormat='dd.MM.yyyy' selected={this.state.from} onChange={this.handleSetFromDate} /></div>
          <div><DatePicker dateFormat='dd.MM.yyyy' selected={this.state.to} onChange={this.handleSetToDate} /></div>
          <div className='tele-more-btn' onClick={() => { this.handleFullCall() }}>
            Ok
          </div>
        </div>

        <div>
          <div className='telemetryPlot'>
            <p>Es liegen keine Daten vor in diesem Zeitraum</p>
          </div>
        </div>
      </Typography>
    )
  }

  handleTimeRangeChange(timerange) {
    const seriesEdit = this.state.series.crop(timerange)

    // Crop Series End
    this.setState({
      series: seriesEdit,
      range: timerange
    })
  };

  handleFullCallBackub() {
    this.apiCall(this.props.rowinfo.RowKey, undefined)
    this.setState({
      moreBtn: false
    })
  }

  handleFullCall () {
    this.setState({ loadingCall: true })
    this.handleGetCall()
  }


  handleSetFromDate = (date) => {
    this.setState({ from: new Date(date).getTime() })
  }

  handleSetToDate = (date) => {
    this.setState({ to: new Date(date).getTime() })
  }

  render() {
    const { series, range, tracker } = this.state

    const dateTime = new Date(tracker).toLocaleString('de-DE')

    if (this.state.loadingCall) {
      return (
        circularProgressCenter()
      )
    }

    return (
      range ? (
        <div>

          <div style={{ display: 'flex' }} className='btn-cont'>
            <div><DatePicker dateFormat='dd.MM.yyyy' selected={this.state.from} onChange={this.handleSetFromDate} /></div>
            <div><DatePicker dateFormat='dd.MM.yyyy' selected={this.state.to} onChange={this.handleSetToDate} /></div>
            <div className='tele-more-btn' onClick={() => { this.handleFullCall() }}>
              Ok
            </div>
          </div>

          <Resizable>
            <ChartContainer
              title={tracker !== null && tracker !== undefined ? ('' + dateTime + ' MEZ') : ('')}
              titleStyle={{ fill: '#555', fontWeight: 500 }}
              timeRange={range}
              format='%d/%m/%Y %H:%M:%S'
              paddingRight={10}
              enablePanZoom
              onTimeRangeChanged={(timerange) => this.setState({ range: timerange })}
              onTrackerChanged={(tracker) => this.setState({ tracker })}
              trackerPosition={this.state.tracker}
              timeAxisTickCount={4}
            >
              <ChartRow height='150'>
                <YAxis
                  id='status'
                  min={1}
                  max={5}
                  width='60'
                  tickCount={5}
                  format={function (n) {
                    if (n === 1) {
                      return 'unknown'
                    } else if (n === 3) {
                      return 'updating'
                    } else if (n === 2) {
                      return 'idle'
                    } else if (n === 4) {
                      return 'uploading'
                    } else if (n === 5) {
                      return 'capturing'
                    }
                  }}
                />
                <Charts>
                  <LineChart
                    axis='status'
                    series={series}
                    style={style}
                  />
                </Charts>
              </ChartRow>
            </ChartContainer>
          </Resizable>
        </div>

      ) : this.renderNoData()
    )
  }
}

export default Status
